import Splide from "/node_modules/@splidejs/splide";

if (!!Splide) {
  var splide = new Splide(".splide", {
    type: "loop",
    autoplay: true,
    pauseOnHover: false,
  });

  splide.mount();
}

const burgerMenu = document.getElementById("burger-menu");

const menuElement = document.getElementById("menu");

burgerMenu.onclick = () => {
  burgerMenu.classList.toggle("active-burger");
  menuElement.classList.toggle("active-menu");
};

menuElement.onblur = () => {
  burgerMenu.classList.remove("active-burger");
  menuElement.classList.remove("active-menu");
};

const navLinks = document.getElementsByClassName("nav-link");

const arrNavLinks = Array(navLinks.length)
  .fill(0)
  .map((el, index) => navLinks[index]);

const scrollItems = arrNavLinks.map((el) =>
  document.getElementById(el.getAttribute("href")?.slice(1))
);

const replaceActiveNavLink = (newActiveId) => {
  arrNavLinks.forEach((el) => {
    const id = el.getAttribute("href")?.slice(1);
    if (id === newActiveId) {
      el.classList.add("active_link");
    } else {
      el.classList.remove("active_link");
    }
  });
};

const scrollToSection = (topOffset) => {
  const headerHeight = document.getElementsByTagName("header")[0].offsetHeight;
  window.scrollTo({
    top: topOffset - headerHeight,
    behavior: "smooth",
  });
};

arrNavLinks.forEach((navLink) => {
  navLink.onclick = (event) => {
    burgerMenu.classList.remove("active-burger");
    menuElement.classList.remove("active-menu");
    event.preventDefault();
    const id = event.target.getAttribute("href")?.slice(1);
    const blockElement = document.getElementById(id);
    replaceActiveNavLink(id);
    scrollToSection(blockElement.offsetTop);
  };
});

document.addEventListener("scroll", () => {
  const headerHeight = document.getElementsByTagName("header")[0].offsetHeight;

  var fromTop = window.pageYOffset + window.innerHeight / 1.5 + headerHeight;

  scrollItems.forEach((sectionItem) => {
    if (sectionItem.offsetTop < fromTop) {
      replaceActiveNavLink(sectionItem.id);
    }
  });
});

document.getElementById("btn-expand_more").onclick = () => {
  const blockElement = document.getElementById("services");
  scrollToSection(blockElement.offsetTop);
};
